import PropTypes from 'prop-types'

export const reportShape = PropTypes.shape({
  production: PropTypes.arrayOf(PropTypes.number),
  changeover: PropTypes.arrayOf(PropTypes.number),
  changeoverCount: PropTypes.arrayOf(PropTypes.number),
  stoppage: PropTypes.arrayOf(PropTypes.number),
  stoppagePlanned: PropTypes.arrayOf(PropTypes.number),
  stoppageUnplanned: PropTypes.arrayOf(PropTypes.number)
})
