import { createAction } from 'redux-actions'

const Actions = {
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  SET_TOKEN: 'SET_TOKEN',
  RECEIVE_SECTORS: 'RECEIVE_SECTORS',
  RECEIVE_LOCATIONS: 'RECEIVE_LOCATIONS',
  REQUEST_REPORTS: 'REQUEST_REPORTS',
  RECEIVE_REPORTS: 'RECEIVE_REPORTS',
  APPLY_FILTERS: 'APPLY_FILTERS'
}

export default Actions

export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const setToken = createAction(Actions.SET_TOKEN)
export const receiveSectors = createAction(Actions.RECEIVE_SECTORS)
export const receiveLocations = createAction(Actions.RECEIVE_LOCATIONS)
export const requestReports = createAction(Actions.REQUEST_REPORTS)
export const receiveReports = createAction(
  Actions.RECEIVE_REPORTS,
  (reports, additionalReports) => ({ reports, additionalReports })
)
export const applyFilters = createAction(Actions.APPLY_FILTERS)
