import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { allIds, allById, idsByCustom, customById } from 'lib/reducers'
import Actions from 'oee/dashboard/actions'
import navigation from 'containers/Navigation/reducer'
import { SET_NAVIGATION } from '../../containers/Navigation/actions'

const emptyReport = () => ({
  oee: { value: 0, threshold: 0 },
  availability: { value: 0, threshold: 0 },
  performance: { value: 0, threshold: 0 },
  quality: { value: 0, threshold: 0 }
})

const emptyAdditionalReport = () => ({
  production: []
})

const extractReportValues = (report) => {
  if (!report) {
    return emptyReport()
  }

  const { oee, availability, technicalAvailability, performance, quality } = report
  return { oee, availability, technicalAvailability, performance, quality }
}

const extractAdditionalReportValues = (report) => {
  if (!report) {
    return emptyAdditionalReport()
  }

  const { production } = report
  return { production }
}

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const plant = handleActions(
  {
    [Actions.RECEIVE_REPORTS]: (state, { payload: { reports, additionalReports } }) => ({
      ...state,
      report: extractReportValues(reports.find(report => report.type === 'plant')),
      additionalReport: extractAdditionalReportValues(additionalReports.find(report => report.type === 'plant'))
    })
  },
  { name: '', report: emptyReport(), additionalReport: emptyAdditionalReport() }
)

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

const sectors = handleActions(
  {
    [Actions.RECEIVE_SECTORS]: (state, { payload: sectors }) => ({
      allById: allById(sectors),
      allIds: allIds(sectors),
      reportById: customById(sectors, emptyReport),
      additionalReportById: customById(sectors, emptyAdditionalReport)
    }),
    [Actions.RECEIVE_REPORTS]: (state, { payload: { reports, additionalReports } }) => ({
      ...state,
      reportById: { ...state.reportById, ...customById(reports.filter(r => r.type === 'sector'), extractReportValues) },
      additionalReportById: {
        ...state.additionalReportById,
        ...customById(additionalReports.filter(r => r.type === 'sector'), extractAdditionalReportValues)
      }
    })
  },
  { allById: {}, allIds: [], reportById: {}, additionalReportById: {} }
)

const locations = handleActions(
  {
    [Actions.RECEIVE_SECTORS]: (state, { payload: sectors }) => ({
      ...state,
      idsBySector: customById(sectors, [])
    }),
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      allById: allById(locations),
      idsBySector: { ...state.idsBySector, ...idsByCustom(locations, 'sectorId') },
      reportById: customById(locations, emptyReport),
      additionalReportById: customById(locations, emptyAdditionalReport)
    }),
    [Actions.RECEIVE_REPORTS]: (state, { payload: { reports, additionalReports } }) => ({
      ...state,
      reportById: { ...state.reportById, ...customById(reports.filter(r => r.type === 'location'), extractReportValues) },
      additionalReportById: {
        ...state.additionalReportById,
        ...customById(additionalReports.filter(r => r.type === 'location'), extractAdditionalReportValues)
      }
    })
  },
  { allById: {}, idsBySector: {}, reportById: {}, additionalReportById: {} }
)

const loading = handleActions(
  {
    [Actions.REQUEST_REPORTS]: () => true,
    [Actions.RECEIVE_REPORTS]: () => false
  },
  false
)

const fresh = handleActions(
  {
    [SET_NAVIGATION]: () => false,
    [Actions.RECEIVE_REPORTS]: () => true
  },
  false
)

export default combineReducers({
  config,
  plant,
  init,
  sectors,
  locations,
  navigation,
  loading,
  fresh
})
