import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectLocation, selectLocationReport, selectLocationAdditionalReport } from 'oee/dashboard/selectors'
import { reportShape } from 'oee/dashboard/shapes'
import * as DashboardShapes from 'reports/dashboard/shapes'
import OEEGraph from 'components/OEEGraph'
import Icon from 'components/Icon'
import ReportGraph from 'components/ReportGraph'
import { Characters } from 'lib/const'

const DashboardLocation = ({ id, name, report, additionalReport, children }) => {
  return (
    <div className='report-item'>
      <div className='report-item__box'>
        <div className='report-item__title'>
          <a
            data-turbolinks='false'
            className='report-item__title-box'
            href={`/oee/dashboard/location/${id}`}
          >
            {name}
          </a>
          <div className='report-item__title-actions'>
            <a
              data-turbolinks='false'
              className='report-item__title-action report-item__title-action--multi'
              href={`/oee/dashboard/location/${id}/changeovers`}
            >
              <Icon name='random' />
            </a>
            <a
              data-turbolinks='false'
              className='report-item__title-action report-item__title-action--multi'
              href={`/oee/dashboard/location/${id}/breakdowns`}
            >
              <Icon name='line-chart' />
            </a>
            <a
              data-turbolinks='false'
              className='report-item__title-action report-item__title-action--multi'
              href={`/oee/dashboard/location/${id}/scrap`}
            >
              <Icon name='area-chart' />
            </a>
          </div>
        </div>

        <OEEGraph report={report.oee} indicator='oee' small />
        <OEEGraph report={report.availability} indicator='availability' small />
        <OEEGraph report={report.technicalAvailability} indicator='technicalAvailability' small />
        <OEEGraph report={report.performance} indicator='performance' small />
        <OEEGraph report={report.quality} indicator='quality' small />
        <ReportGraph values={additionalReport.production} character={Characters.PRODUCTION} small showLabel={false} color='warning' />
        {children}
      </div>
    </div>
  )
}

DashboardLocation.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  report: reportShape,
  additionalReport: DashboardShapes.reportShape,
  children: PropTypes.any
}

const mapStateToProps = (state, { id }) => ({
  ...selectLocation(state, id),
  report: selectLocationReport(state, id),
  additionalReport: selectLocationAdditionalReport(state, id)
})

export default connect(mapStateToProps)(DashboardLocation)
