import React from 'react'
import { connect } from 'react-redux'
import ReportHeader from 'components/ReportHeader'
import Button from 'components/Button'
import { selectPlant } from 'oee/dashboard/selectors'
import PropTypes from 'prop-types'
import { applyFilters } from './actions'
import { selectIsFresh } from './selectors'
import { FormattedMessage } from 'react-intl'

const DashboardHeader = ({ isFresh, onApplyFiltersClick, ...rest }) => {
  const buttonBrightness = isFresh ? '100%' : '150%'

  return (
    <ReportHeader {...rest}>
      <div className='report-header__navigation-item'>
        <Button
          style={{ filter: `brightness(${buttonBrightness})` }}
          color='blue'
          onClick={onApplyFiltersClick}
        >
          <FormattedMessage
            id='applyFilters'
          />
        </Button>
      </div>
    </ReportHeader>
  )
}

DashboardHeader.propTypes = {
  ...ReportHeader.propTypes,
  onApplyFiltersClick: PropTypes.func
}

const mapStateToProps = state => ({
  name: selectPlant(state).name,
  isFresh: selectIsFresh(state)
})

const mapDispatchToProps = {
  onApplyFiltersClick: applyFilters
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader)
