import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import Progress, { ProgressBar } from 'components/Progress'

const INDICATORS = ['oee', 'availability', 'technicalAvailability', 'performance', 'quality']

const OEEProgress = ({ value, threshold, small }) => (
  <Progress value={value} small={small} >
    {
      value >= threshold &&
      <ProgressBar color='success' value={value} />
    }
    {
      value < threshold &&
      <React.Fragment>
        <ProgressBar color='danger' value={value} />
        <ProgressBar color='secondary' value={threshold - value} />
      </React.Fragment>
    }
  </Progress>
)

OEEProgress.propTypes = {
  value: PropTypes.number.isRequired,
  threshold: PropTypes.number.isRequired,
  small: PropTypes.bool
}

const OEEGraphLabelMessage = ({ indicator }) => {
  switch (indicator) {
    case 'oee':
      return <FormattedMessage id='components.oeeGraph.oee' defaultMessage='OEE' />
    case 'availability':
      return <FormattedMessage id='components.oeeGraph.availability' defaultMessage='Availability' />
    case 'technicalAvailability':
      return <FormattedMessage id='components.oeeGraph.technicalAvailability' defaultMessage='Technical Availability' />
    case 'performance':
      return <FormattedMessage id='components.oeeGraph.performance' defaultMessage='Performance' />
    case 'quality':
      return <FormattedMessage id='components.oeeGraph.quality' defaultMessage='Quality' />
  }
}

OEEGraphLabelMessage.propTypes = {
  indicator: PropTypes.oneOf(INDICATORS)
}

const OEEGraph = ({ report, showLabel, small, indicator }) => {
  const { value, threshold } = report

  return (
    <div className={classnames('report-graph', `report-graph--${value >= threshold ? 'success' : 'danger'}`, { 'report-graph--small': small })}>
      {
        showLabel &&
        <div className='report-graph__label'>
          <span className='report-graph__character'>
            <OEEGraphLabelMessage indicator={indicator} />
          </span>
          <span className='report-graph__percentage'>
            {
              isNaN(value)
                ? '---'
                : value.toFixed(1) + '%'
            }
          </span>
        </div>
      }

      <div className='report-graph__content'>
        <div className='report-graph__graph'>
          {
            !showLabel &&
            <div className='report-graph__details'>
              <div className='report-graph__small-label'>
                <OEEGraphLabelMessage indicator={indicator} /> [{ isNaN(value) ? '---' : value.toFixed(1) + '%' }]
              </div>
            </div>
          }
          <OEEProgress value={value} threshold={threshold} small={small} />
        </div>
      </div>
    </div>
  )
}

OEEGraph.propTypes = {
  report: PropTypes.shape({
    value: PropTypes.number.isRequired,
    threshold: PropTypes.number.isRequired
  }),
  showLabel: PropTypes.bool,
  small: PropTypes.bool,
  indicator: PropTypes.oneOf(INDICATORS)
}

export default OEEGraph
