import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectSector,
  selectSectorReport,
  selectSectorAdditionalReport
} from 'oee/dashboard/selectors'
import { reportShape } from 'oee/dashboard/shapes'
import * as DashboardShapes from 'reports/dashboard/shapes'
import OEEGraph from 'components/OEEGraph'
import ReportGraph from 'components/ReportGraph'
import { Characters } from 'lib/const'

const DashboardSector = ({ id, report, additionalReport, sector, children }) => {
  return (
    <div className='report-section'>
      <div className='report-section__data'>
        <h1 className='report-section__title'>
          {sector.name}
        </h1>
        <div className='report-section__graphs'>
          <OEEGraph report={report.oee} indicator='oee' small />
          <OEEGraph report={report.availability} indicator='availability' small />
          <OEEGraph report={report.technicalAvailability} indicator='technicalAvailability' small />
          <OEEGraph report={report.performance} indicator='performance' small />
          <OEEGraph report={report.quality} indicator='quality' small />
          <ReportGraph values={additionalReport.production} character={Characters.PRODUCTION} small showLabel={false} color='warning' />
        </div>
      </div>

      <div className='report-section__locations'>
        {children}
      </div>
    </div>
  )
}

DashboardSector.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.any,
  report: reportShape,
  additionalReport: DashboardShapes.reportShape,
  sector: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
}

const mapStateToProps = (state, { id }) => ({
  report: selectSectorReport(state, id),
  sector: selectSector(state, id),
  additionalReport: selectSectorAdditionalReport(state, id)
})

export default connect(mapStateToProps)(DashboardSector)
