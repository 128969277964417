import React from 'react'
import PropTypes from 'prop-types'
import Progress from 'components/Progress'
import { Characters } from 'lib/const'
import Unit from 'components/Unit'

const Value = ({ value, character }) => (
  <Unit
    value={value ? value.toFixed(0) : value}
    unit={[Characters.PRODUCTION, Characters.SCRAP].includes(character) ? 'piece' : 'minute'}
  />
)

Value.propTypes = {
  value: PropTypes.number,
  character: Characters.propType
}

const ReportGraphDetails = ({ planned, realized, character }) => {
  let percentage
  if (planned === 0 && realized > 0) {
    percentage = 100.0
  } else if (planned === 0 && realized === 0) {
    percentage = 0.0
  } else {
    percentage = Math.round(1000 * realized / planned) / 10.0
  }

  return (
    <div className='report-graph__details'>
      <span className='report-graph__realized'><Value value={realized} character={character} /></span>
      <span className='report-graph__divider'>&nbsp;/&nbsp;</span>
      <span className='report-graph__planned'><Value value={planned} character={character} /></span>
      <span> {percentage.toFixed(1) + '%'}</span>
    </div>
  )
}

ReportGraphDetails.defaultProps = {
  small: Progress.defaultProps.small
}

ReportGraphDetails.propTypes = {
  planned: PropTypes.number,
  realized: PropTypes.number,
  character: Characters.propType
}

export default ReportGraphDetails
